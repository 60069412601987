<template>
  <b-card
    class="statementreceiveitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="statementreceiveitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款单ID"
            label-for="receive_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_id"
              size="sm"
              v-model="statementreceiveitem.receive_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款单编号"
            label-for="receive_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_no"
              size="sm"
              v-model="statementreceiveitem.receive_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单ID"
            label-for="statement_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_id"
              size="sm"
              v-model="statementreceiveitem.statement_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单编号"
            label-for="statement_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_no"
              size="sm"
              v-model="statementreceiveitem.statement_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="本次收款金额"
            label-for="receive_current"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_current"
              size="sm"
              v-model="statementreceiveitem.receive_current"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementreceiveitemStore from './statementreceiveitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      statementreceiveitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('statementreceiveitem')) store.registerModule('statementreceiveitem', statementreceiveitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementreceiveitem')) store.unregisterModule('statementreceiveitem')
    })

    const edit = function() {
      store.dispatch('statementreceiveitem/edit', {id: this.id}).then(res => {
        this.statementreceiveitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('statementreceiveitem/view', {id: this.id}).then(res => {
        this.statementreceiveitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('statementreceiveitem/save', this.statementreceiveitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>